<template>
  <CriaTabSel />
</template>

<script>
import CriaTabSel from "@/components/tabSel/CriaTabSelTipo.vue";

export default {
  components: {
    CriaTabSel,
  },
};
</script>
